<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="row">
            <div class="col-lg-12" style="margin-top: -223px;width: 1980px;overflow: hidden;height: 750px">
                <div class="left">
                    <div class="left_world" style="margin-top: 105px">
                        <h1 class="successsample01">adidas</h1>
                        <h2 class="successsample01">Keep the leading sports brand green!</h2>
                        <p class="successsample01">adidas has its roots in Germany but it’s a truly global company.
                            Every year it produce over
                            900 million sports and sports lifestyle products with independent manufacturing partners
                            worldwide. In 2017 it generated sales of € 21.218 billion. These numbers alone can easily
                            suggest that adidas is quite a large and also multifaceted organization.</p>
                        <p class="successsample01">CINO Recycling deliver the above services for adidas since 2012.</p>
                        <h5 class="successsample01" style="font-size: 17px;color: black;margin-top: 45px">Our
                            Services</h5>
                        <ul style="list-style-type: disc !important;">
                            <li class="successsample01">IT Recycling</li>
                            <li class="successsample01">ogistic services</li>
                            <li class="successsample01">Data Security and Destruction</li>
                        </ul>
                        <h5 class="successsample01" style="font-size: 17px;color: black">Our Achievements</h5>
                        <ul style="list-style-type: disc !important;">
                            <li class="successsample01">High customer satisfaction with professional service</li>
                            <li class="successsample01">100% High availability of Date Destruction</li>
                            <li class="successsample01">Year-end IT Stock Taking</li>
                        </ul>
                    </div>
                </div>
                <div class="right">
                    <img alt=""
                         src="../../../assets/image/successSample/01.webp">
                </div>
            </div>


            <div class="col-lg-12" style="width: 1980px;overflow: hidden;height: 750px">
                <div class="left">
                    <div class="left_world" style="margin-top: 45px">
                        <h1 class="successsample02">China</h1>
                        <h1 class="successsample02">Eastern</h1>
                        <h2 class="successsample02">Recycling Made Easy</h2 >
                        <p class="successsample02">Headquartered in Shanghai, China Eastern Air Holding Co.Ltd (CEAH) is
                            one of the three major
                            air transportation groups in China and could be traced back as the first squadron
                            established by former Civil Aviation Administration of Shanghai in January, 1957. </p>
                        <p class="successsample02">CINO Recycling deliver the above services for China Eastern since
                            2016.</p>
                        <h5 class="successsample02" style="font-size: 17px;color: black;margin-top: 45px">Our
                            Services</h5>
                        <ul style="list-style-type: disc !important;">
                            <li class="successsample02">IT Recycling</li>

                            <li class="successsample02">Data Security and Destruction</li>
                        </ul>
                        <h5 class="successsample02" style="font-size: 17px;color: black">Achievements</h5>
                        <ul style="list-style-type: disc !important;">
                            <li class="successsample02">Perfect user experience with successful service</li>
                            <li class="successsample02">100% High availability of Date Destruction</li>

                        </ul>
                    </div>
                </div>
                <div class="right">
                    <img alt=""
                         src="../../../assets/image/successSample/02.webp">
                </div>
            </div>

            <div class="col-lg-12" style="margin-top: 75px">
                <div class="conatiner_text">
                    <h1 class="successsample03">CINO RECYCLING DEMO VIDEO</h1>
                    <h1 class="successsample03">of</h1>
                    <h1 class="successsample03">DATA SECURITY AND DESTRUCTION</h1>
                    <h4 class="successsample03">Seeing is Believing</h4>
                </div>
            </div>

        </div>
        <Bottom style="margin-left: -35px"></Bottom>
    </div>
</template>

<script>
    import {Head, Bottom} from '../commponents'

    export default {

        name: "SuccessSample",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        components: {Bottom, Head},
        created() {
            console.log(1)
            $(function () {
            $('.successsample01').animate({
                opacity: '1',
                top: '15px',

            }, 1000)
            })


            $(window).scroll(function () {
                var s = $(window).scrollTop();

                if (s > 350) {
                    $('.successsample02').animate({
                        opacity: '1',
                        top: '-15px',

                    }, 1000)
                }
                if (s > 750) {
                    $('.successsample03').animate({
                        opacity: '1',
                        top: '15px',

                    }, 1000)
                }

            });
        },
        watch:{
            $route(to , from){
                $('.successsample01').animate({
                    opacity: '1',
                    top: '15px',

                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();

                    if (s > 350) {
                        $('.successsample02').animate({
                            opacity: '1',
                            top: '-15px',

                        }, 1000)
                    }
                    if (s > 750) {
                        $('.successsample03').animate({
                            opacity: '1',
                            top: '15px',

                        }, 1000)
                    }

                });
            }
        }
    }



    $(function () {

        $('.successsample01').animate({
            opacity: '1',
            top: '15px',

        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();

            if (s > 350) {
                $('.successsample02').animate({
                    opacity: '1',
                    top: '-15px',

                }, 1000)
            }
            if (s > 750) {
                $('.successsample03').animate({
                    opacity: '1',
                    top: '15px',

                }, 1000)
            }

        });


    })

</script>

<style scoped>
    .successsample01{
        opacity: 0;
        position: relative;
    }
    .successsample02 {
        opacity: 0;
        position: relative;
    }

    .successsample03 {
        opacity: 0;
        position: relative;
    }

    .col-lg-12 .left {
        float: left;
        height: 750px;
        width: 800px;

        position: relative;
    }

    .left .left_world {
        height: 750px;
        width: 500px;
        position: absolute;
        right: 0px;

    }

    .left_world h1 {
        color: black;
        font-weight: 800;

        text-align: center;
    }

    .left_world h2 {
        color: black;
        font-weight: 700;
        margin-top: 45px;
        font-size: 15px;
        text-align: center;
        margin-bottom: 25px;
    }

    .left_world p {
        color: black;
        line-height: 30px;
    }

    .left_world ul {

        display: inline-block;
        height: 80px;
        width: 100%;
        text-align: left !important;

    }

    .left_world ul li {
        display: inline-block;
        height: 25px;
        width: 100%;
        text-align: left !important;
        margin-left: 15px;
        font-size: 14px;

    }

    .left_world ul li::before {
        content: "";
        height: 8px;
        width: 8px;
        position: absolute;
        opacity: .9;
        background-color: black;
        margin-left: -12px;
        margin-top: 6px;
        border-radius: 50%;


    }

    .col-lg-12 .right {
        float: left;
        height: 750px;
        width: 700px;

    }

    .right img {
        margin-left: 45px;
        margin-top: 45px;
        box-shadow: 20px 10px 0px rgba(0, 0, 0, 1);
    }

    .col-lg-12 .conatiner_text {

        text-align: center;
    }

    .conatiner_text h1 {
        color: black;
    }

    .conatiner_text h4 {
        color: black;
    }
</style>